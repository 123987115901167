import styled from 'styled-components'
import Img from 'gatsby-image'

import TicketBg from '../images/cloud-rendering-promotion/ticket_bg.png'
import EffectBg from '../images/cloud-rendering-promotion/effect_bg.png'
import AnimationBg from '../images/cloud-rendering-promotion/animation_bg.png'
import Price1Bg from '../images/cloud-rendering-promotion/price1_bg.png'
import Price2Bg from '../images/cloud-rendering-promotion/price2_bg.png'
import Price3Bg from '../images/cloud-rendering-promotion/price3_bg.png'
import Price1TitleBg from '../images/cloud-rendering-promotion/price1_title.png'
import Price2TitleBg from '../images/cloud-rendering-promotion/price2_title.png'
import AboutBg from '../images/cloud-rendering-promotion/about_bg.png'
import FourBg from '../images/cloud-rendering-promotion/four_bg.png'
import logoIcon from '../images/cloud-rendering-promotion/logo.png'

// common
export const CenterTitle = styled.div`
  padding-top: ${p => (p.top ? p.top + 'px' : '80px')};
  font-size: 40px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
`
export const MainBox = styled.div`
  max-width: 1280px;
  margin: auto;
  padding-top: ${p => (p.top ? p.top + 'px' : '80px')};
  padding-bottom: ${p => (p.bottom ? p.bottom + 'px' : '0px')};
`

// 第一部分
export const BannerContainer = styled.div`
  min-height: 1080px;
  width: 100%;
  position: relative;
  border-top: 1px solid transparent;
  background-color: #000000;
  ::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    background-image: url(${logoIcon});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    width: 170px;
    height: 36px;
    z-index: 1;
  }
`

export const BannerWapper = styled.div`
  width: 1280px;
  height: 650px;
  margin: auto;
  margin-top: 140px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  .right {
    flex-basis: 468px;
    height: 720px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
  }
`
export const BannerTitle = styled.h1`
  padding-top: 60px;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  margin: 0;
`

export const BannerSubTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  margin-top: 70px;
  span {
    color: #13cb75;
  }
`
export const BannerTicketBox = styled.div`
  width: 627px;
  height: 121px;
  margin-top: 29px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${TicketBg});
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`

export const TicketBox = styled.div`
  width: 290px;
  height: 60px;
  margin-left: 41px;
  display: flex;
`

export const TicketImg = styled.div`
  flex-basis: 71px;
  flex-grow: 0;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
  }
`
export const TicketTextBox = styled.div`
  flex: 1;
  padding-left: 20px;
`

export const TicketType = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
`

export const TicketText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 27px;
`

export const PropagandaTitle = styled(CenterTitle)`
  background-color: #000000;
  line-height: 56px;
`
export const AdvantageBox = styled.div`
  width: 1280px;
  height: 115px;
  margin: auto;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
`

export const AdvantageContent = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 55px;
    height: 60px;
  }
  div {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    line-height: 72px;
  }
`

export const Billboard = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  z-index: 0;
`

// 第二部分
export const ClientContainer = styled.div`
  background-color: #000000;
`
export const ClientWarpper = styled.div`
  width: 1280px;
  margin: auto;
`

export const AdvantageDetail = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
`
export const Detail = styled.div`
  color: #fff;
  padding: 22px 50px 0px 50px;
  flex: 1;
  font-size: 16px;
  color: #999999;
  line-height: 28px;
  text-align: center;
  p {
    margin: 0;
  }
`
export const FeaturesBox = styled.div`
  margin-top: 120px;
  width: 1280px;
  height: 220px;
  position: relative;
  > img {
    position: absolute;
    width: 329px;
    height: 261px;
    z-index: 5;
    top: -60px;
  }
  &.left {
    img {
      left: 0px;
    }
    .features {
      right: 0px;
    }
  }
  &.right {
    img {
      right: 0px;
    }
    .features {
      left: 0px;
    }
  }
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${EffectBg});
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  :nth-child(3)::before {
    background-image: url(${AnimationBg});
  }
`

export const Features = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: calc(100% - 329px);
  z-index: 5;
`

export const FeaturesTitle = styled.div`
  padding-left: 40px;
  margin-top: 30px;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  line-height: 26px;
`

export const FeaturesItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 44px 40px 0px 40px;
`

export const FeaturesItem = styled.div`
  flex-basis: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 60px;
    height: 50px;
  }
  .animation {
    width: 50px;
  }
  p {
    margin: 14px auto;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
`

export const PriceBox = styled.div`
  margin-top: 90px;
  width: 100%;
  height: 361px;
  display: flex;
  justify-content: space-between;
`

export const PriceItem = styled.div`
  height: 100%;
  width: 301px;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${Price1Bg});
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &.price2_bg {
    ::before {
      background-image: url(${Price2Bg});
    }
  }
  &.price3_bg {
    ::before {
      background-image: url(${Price3Bg});
    }
  }
`

export const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 67px;
  width: 207px;
  margin: 0 auto;
  transform: translateY(-17px);
  span {
    position: absolute;
    color: #fff;
    line-height: 56px;
    z-index: 10;
  }
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${Price1TitleBg});
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  &.animation {
    ::before {
      background-image: url(${Price2TitleBg});
    }
  }
`

export const PriceContent = styled.div`
  position: relative;
  z-index: 5;
`

export const PriceIdentity = styled.div`
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
`

export const PriceShow = styled.div`
  display: flex;
  height: 48px;
  text-align: center;
  color: #fff;
  width: 170px;
  margin: 0 auto;
  position: relative;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  .left {
    font-size: 24px;
    font-family: Arial;
    font-weight: normal;
    color: #4785f2;
    line-height: 18px;
  }
  .special {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .green_special {
      color: #13cb75;
    }
    span:nth-child(1) {
      line-height: 18px;
      text-align: right;
    }
    span:nth-child(2) {
      color: #999999;
      font-size: 14px;
      text-align: right;
    }
  }
  .mid {
    font-size: 48px;
    font-weight: 900;
    color: #4785f2;
    line-height: 48px;
    margin: 0 5px;
    white-space: nowrap;
  }
  .green_special {
    color: #13cb75;
  }
  .small_mid {
    text-align: center;
    p {
      height: 27px;
      font-size: 20px;
      font-weight: 400;
      color: #13cb75;
      line-height: 23px;
      margin: 0;
    }
  }
  .right {
    font-size: 16px;
    font-family: Arial;
    font-weight: normal;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    white-space: nowrap;
  }
`

export const PriceDetail = styled.div`
  margin: 47px auto;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column-reverse;
  height: 60px;
  p {
    margin: 0 5px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
`

export const RegisteButtonBox = styled.div`
  margin-top: 60px;
  height: 80px;
`

export const RegisteButton = styled.div`
  width: 300px;
  height: 60px;
  background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
  border-radius: 30px;
  margin: 0 auto;
  text-align: center;
  line-height: 60px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  :hover {
    box-shadow: 0px 6px 11px 1px rgba(64, 194, 93, 0.3);
  }
`
export const IconBox = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const IconItem = styled.div`
  width: 160px;
  border-radius: 10px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img_box {
    width: 90px;
    height: 90px;
    background: #333333;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 60px;
    }
  }
  .text {
    margin-top: 24px;
    text-align: center;
    font-size: 18px;
    font-family: Open Sans;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
  }
`
export const SmallPropagandaTitle = styled(PropagandaTitle)`
  padding-top: 0px;
  padding-bottom: 100px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
`
// 第三部分
export const AbooutContainer = styled.div`
  width: 100%;
  background-color: #000000;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${AboutBg});
    background-size: 100% 980px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    width: 100%;
    height: 800px;
    z-index: 0;
  }
`

export const AboutWapper = styled.div`
  position: relative;
  z-index: 5;
`

export const AboutTitle = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
  padding-top: 122px;
`

export const AboutText = styled.div`
  padding-top: 39px;
  margin: auto;
  width: 999px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
`

export const AboutFour = styled.div`
  width: 1280px;
  margin: auto;
  margin-top: 59px;
  height: 241px;
  display: flex;
  justify-content: space-between;
`

export const AboutFourItem = styled.div`
  width: 301px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    height: 60px;
    margin-top: 41px;
  }
  .text {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 72px;
  }
  .sub_text {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${FourBg});
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`

export const AboutCenterTitle = styled.div`
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
`

export const GifBox = styled(MainBox)`
  min-height: 783px;
  display: flex;
  justify-content: space-between;
`
export const GifListBox = styled.div`
  width: 418px;
  height: 783px;
  img {
    width: 100%;
    border-radius: 5px;
  }
  .left1 {
    height: 161px;
  }
  .left2 {
    margin-top: 14px;
    height: 433px;
  }
  .left3 {
    margin-top: 13px;
    height: 162px;
  }
  .mid1 {
    height: 377px;
  }
  .mid2 {
    margin-top: 13px;
    height: 161px;
  }
  .right1 {
    height: 279px;
  }
  .right2 {
    margin-top: 11px;
    height: 166px;
  }
  .right3 {
    margin-top: 11px;
    height: 297px;
  }
`
export const NewSmallPropagandaTitle = styled(CenterTitle)`
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 72px;
`

export const CompanyBox = styled(MainBox)`
  display: flex;
  justify-content: space-between;
`

export const CompanyItem = styled.div`
  width: 241px;
  height: 81px;
  img {
    width: 100%;
    height: 100%;
  }
`
// 第四部分
export const Register = styled.div`
  width: 100%;
  height: 250px;
  background: #13cb75;
`
export const RegisterTitle = styled(CenterTitle)`
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
  height: 86px;
  line-height: 36px;
`
export const RegisterSubTitle = styled(CenterTitle)`
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
`
export const RegisterButton = styled.div`
  width: 180px;
  height: 50px;
  border-radius: 25px;
  background: #ffffff;
  box-shadow: 0px 6px 11px 1px rgba(9, 102, 59, 0.3);
  margin: auto;
  text-align: center;
  margin-top: 29px;
  font-size: 20px;
  font-weight: 400;
  color: #13cb75;
  line-height: 50px;
  cursor: pointer;
`

export const BottomBox = styled.div`
  width: 100%;
  height: 78px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 26px;
    height: 30px;
    margin-right: 9px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
`
